exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-community-partners-tsx": () => import("./../../../src/pages/community-partners.tsx" /* webpackChunkName: "component---src-pages-community-partners-tsx" */),
  "component---src-pages-couples-therapy-tsx": () => import("./../../../src/pages/couples-therapy.tsx" /* webpackChunkName: "component---src-pages-couples-therapy-tsx" */),
  "component---src-pages-emdr-tsx": () => import("./../../../src/pages/emdr.tsx" /* webpackChunkName: "component---src-pages-emdr-tsx" */),
  "component---src-pages-family-therapy-tsx": () => import("./../../../src/pages/family-therapy.tsx" /* webpackChunkName: "component---src-pages-family-therapy-tsx" */),
  "component---src-pages-group-therapy-tsx": () => import("./../../../src/pages/group-therapy.tsx" /* webpackChunkName: "component---src-pages-group-therapy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-life-transitions-therapy-tsx": () => import("./../../../src/pages/life-transitions-therapy.tsx" /* webpackChunkName: "component---src-pages-life-transitions-therapy-tsx" */),
  "component---src-pages-rates-tsx": () => import("./../../../src/pages/rates.tsx" /* webpackChunkName: "component---src-pages-rates-tsx" */),
  "component---src-pages-sandtray-therapy-tsx": () => import("./../../../src/pages/sandtray-therapy.tsx" /* webpackChunkName: "component---src-pages-sandtray-therapy-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-tf-cbt-tsx": () => import("./../../../src/pages/tf-cbt.tsx" /* webpackChunkName: "component---src-pages-tf-cbt-tsx" */),
  "component---src-pages-trauma-therapy-tsx": () => import("./../../../src/pages/trauma-therapy.tsx" /* webpackChunkName: "component---src-pages-trauma-therapy-tsx" */)
}

